exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-build-js": () => import("./../../../src/pages/build.js" /* webpackChunkName: "component---src-pages-build-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-planning-js": () => import("./../../../src/pages/planning.js" /* webpackChunkName: "component---src-pages-planning-js" */),
  "component---src-pages-politics-js": () => import("./../../../src/pages/politics.js" /* webpackChunkName: "component---src-pages-politics-js" */),
  "component---src-pages-remodeling-js": () => import("./../../../src/pages/remodeling.js" /* webpackChunkName: "component---src-pages-remodeling-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-js": () => import("./../../../src/pages/thank.js" /* webpackChunkName: "component---src-pages-thank-js" */)
}

